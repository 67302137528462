<template>
  <!-- <v-card color="grey lighten-4" min-width="350px" flat> -->
  <v-container fill-height class="payment-selector">
    <v-row justify="end">
      <v-btn text @click="logout">SIGN OUT</v-btn>
    </v-row>
    <v-row align="center" justify="center">
      <img src="/img/logos/TP-Jobs-RGB@2x.png" alt="" />
    </v-row>
    <v-row align="center" justify="center" class="display-2">
      <span>It appears you don't have a subscription yet. </span>
      <span>Please select a plan for your new subscription. </span>
    </v-row>
    <payment-selector
      v-model="yearlySubscription"
      @subscriptionChange="changeSubscription" />

    <v-row align="center" justify="center" class="display-2">
      <v-btn
        class="phase-green mx-auto"
        dark
        x-large
        max-width="600"
        min-width="400"
        text
        @click="paymentSubmit()">
        Get Started!
      </v-btn>
    </v-row>
    <v-row align="center" justify="center">
      If you feel like this is in error please contact support.
    </v-row>
  </v-container>
  <!-- </v-card> -->
</template>

<script>
  import Stripe from '@/services/Stripe.service.js';
  import PaymentSelector from '@/components/payment/PaymentSelector';

  export default {
    components: {
      PaymentSelector,
    },
    data() {
      return {
        yearlySubscription: false,
        clientConfigurations: {},
      };
    },
    async mounted() {
      this.clientConfigurations = this.$getConfig().client_configurations;
    },
    methods: {
      async paymentSubmit() {
        const uuid = this.$route.query.uuid;

        const successUrl = window.location.origin + '/success';
        const cancelUrl = window.location.origin + '/cancel';
        const pricecode = this.yearlySubscription
          ? this.clientConfigurations.stripe.yearly_price_code
          : this.clientConfigurations.stripe.monthly_price_code;
        const checkoutURL = await Stripe.getCheckoutSession(
          pricecode,
          uuid,
          successUrl,
          cancelUrl
        );

        if (checkoutURL) {
          window.location.href = checkoutURL;
        }
      },
      changeSubscription(yearly) {
        this.yearlySubscription = yearly;
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      },
    },
  };
</script>

<style scoped>
  .thanks-description {
    margin: 10%;
  }
</style>
